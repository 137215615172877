import { useEffect, useState } from 'react';

export const useAdjustUrlMapping = () => {
  const [adjustMapping, setAdjustMapping] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get('utm_source');
    const utmCampaign = searchParams.get('utm_campaign');
    const utmContent = searchParams.get('utm_content');
    const utmTerm = searchParams.get('utm_term');

    const currentUrlMapping = {};
    if (utmSource || utmCampaign) {
      let campaign = utmSource || utmCampaign;
      // Concat the source and campaign if both is present
      if (utmSource && utmCampaign) {
        campaign = `${campaign}_${utmCampaign}`;
      }

      currentUrlMapping.campaign = campaign;
    }
    if (utmTerm) {
      currentUrlMapping.adgroup = utmTerm;
    }
    if (utmContent) {
      currentUrlMapping.creative = utmContent;
    }

    setAdjustMapping(currentUrlMapping);
  }, []);

  return adjustMapping;
};
