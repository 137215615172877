import React from 'react';

import dataSocial from '../../../data/dataSocial.json';
import Image from '../../image';
import Swiper from 'react-id-swiper';
// import { useStaticQuery, graphql } from "gatsby"
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper';
import 'swiper/components/pagination/pagination.min.css';

import { Helmet } from 'react-helmet-async';
// import Img from "gatsby-image"
SwiperCore.use([Autoplay, EffectFade, Pagination]);

const sliderParams = {
  slidesPerView: 0,
  centeredSlides: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  fadeEffect: { crossFade: true },
  speed: 500,
  autoplayDisableOnInteraction: true,

  effect: 'fade',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
};

const SocialProofQuoteCard = ({ lang, quotes, dataTheme }) => {
  const language = typeof lang === 'undefined' ? 'se_sv' : lang;

  const content = dataSocial[language];

  return (
    <section data-theme={dataTheme} className="SocialProofQuoteCard">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper/swiper-bundle.css"
        />
        {' '}
        <script src="https://unpkg.com/swiper/swiper-bundle.js" />
      </Helmet>
      <div className="container container--lg  margin-bottom--xl margin-bottom--sm">
        <div className="stats_bar">
          <div className="float grid flex flex--center-y cardColor">
            <div className="col col--lg-5 display--lg ">
              <div style={{ overflow: 'hidden' }} className="margin-right--lg">
                <Swiper {...sliderParams} style={{ width: '100%!important' }}>
                  {quotes
                    && quotes.map((quote) => (
                      <div key={quote.name}>
                        <div
                          className="swiper-slide"
                          style={{ width: '100%!important' }}
                        >
                          <p>
                            “
                            {quote.text}
                            ”
                            <span className="opacity--6 margin-left--xxs">
                              -
                              {' '}
                              {quote.name}
                            </span>
                            <br />
                          </p>
                        </div>
                      </div>
                    ))}
                </Swiper>
              </div>
            </div>
            <div className="col col--12 col--lg-7">
              <div className="flex flex--space-between flex--center-y ">
                {content
                  && content.map((cards) => (
                    <div
                      key={cards.title}
                      className="text--center"
                      style={{ flexShrink: '0' }}
                    >
                      <p
                        className="text--xl text--title text--700"
                        style={{ lineHeight: '1em' }}
                      >
                        {cards.data}
                      </p>

                      <p className="text--sm text--title opacity--8 margin-bottom--xxs">
                        {cards.title}
                      </p>

                      <Image
                        classname="social-icons"
                        filename={cards.icon}
                        alt={cards.icon}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialProofQuoteCard;
