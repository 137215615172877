import React from "react"
import Button from "../../../components/Button"

const HeroText = ({
  dataTheme,
  title,
  text,
  download,
  downloaddataGoal,
  downloadhref,
  downloadclassname,
  downloadtext,
  heroTextMargin,
}) => {
  return (
    <section data-theme={dataTheme} id="top" style={{ overflow: "visible" }}>
      <div className="container container--sm margin-top--lg">
        <div className={`col col--12 margin-bottom--lg ${heroTextMargin}`}>
          <div className="text--component text--center">
            <h1>{title}</h1>
            <p className="">{text}</p>
            {download && (
              <Button
                dataGoal={downloaddataGoal}
                href={downloadhref}
                classname={downloadclassname}
                text={downloadtext}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroText
