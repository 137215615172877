import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import { useAdjustUrlMapping } from '@/src/common/hooks/utmHooks';

const thousandsSeparators = (num) => {
  const numParts = num.toString().split('.');
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return numParts.join('.');
};

const CtaMailCard = ({ dataTheme, market, source, successPage, sourceReferrer }) => {
  const adjustMapping = useAdjustUrlMapping();
  const [number, setNumber] = useState('');
  const emailRef = useRef(null);

  const onSubmit = (event) => {
    event.preventDefault();

    if (window.analytics && emailRef.current) {
      const params = {
        email: emailRef.current.value,
        sourceReferrer,
        market,
        source,
      };
      window.analytics.identify({ ...params, ...adjustMapping });
      let url = `${successPage}?email=${encodeURIComponent(emailRef.current.value)}`;
      // add utm params + email + entryPoint
      if (window.location.search) {
        url = `${url}&${window.location.search.replace('?', '')}`;
      }
      if (sourceReferrer) {
        url = `${url}&sourceReferrer=${sourceReferrer}`;
      }

      navigate(url);
    }
  };

  const d = new Date();
  const add1 = d.getDate() * 52;
  const add2 = d.getDate() * 2;
  const add3 = add1 + add2;

  useEffect(() => {
    if (typeof number !== 'undefined' && number != null) {
      const add4 = 1 + 1000 + add3 + 1;
      setNumber(thousandsSeparators(add4));
    }
  }, [number, add3]);

  return (
    <section data-theme={dataTheme} className="CtaMailCard">
      <div
        className="container container--sm margin-bottom--lg"
        style={{ backgroundColor: '#edf4fc!important' }}
      >
        <div className="card col col--12 col--md-12 flex flex--center accessFormCard">
          <div className="text--component text--center">
            <p className="margin-bottom--md">
              <span
                id="number"
                className="text--lg text--bold"
                style={{ color: '#5096e8' }}
              >
                {number}
              </span>
              {' '}
              people have signed up for early access
            </p>

            <form
              id="trialForm"
              method="post"
              className="validate form"
              data-goal="Onboarding Viewed"
              onSubmit={onSubmit}
              style={{ margin: '0 auto' }}
            >
              <input type="hidden" name="u" value="af6b2530d423199a95fe20a13" />
              <input type="hidden" name="id" value="bb592d9989" />
              <div>
                <input
                  ref={emailRef}
                  type="email"
                  autoCapitalize="off"
                  autoCorrect="off"
                  name="email"
                  id="email"
                  className="margin-right--xs form-control form-control--md form-control--white text--left margin-bottom--sm"
                  placeholder="Enter your email"
                  onFocus={() => {
                    if (emailRef.current) {
                      emailRef.current.placeholder = '';
                    }
                  }}
                  onBlur={() => {
                    if (emailRef.current) {
                      emailRef.current.placeholder = 'Enter your email';
                    }
                  }}
                  required
                  style={{ borderRadius: '50px', textAlign: 'center' }}
                />
                <input
                  id="emailCampaign"
                  type="submit"
                  value="Get Early Access"
                  name="submit"
                  className="btn btn--primary btn--md btn--fullwidth-sm"
                  style={{ width: '100%' }}
                />
              </div>
              <div
                style={{ position: 'absolute', left: '-5000px' }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_af6b2530d423199a95fe20a13_7c0cc7163d"
                  tabIndex="-1"
                />
              </div>
            </form>

            <p className="margin-top--sm text--xs">
              The information you provide will be used according to our
              {' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.jointacademy.com/us/en/policy/"
              >
                privacy policy.
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaMailCard;
