import React from 'react';
import Video from '../../Video';

const Testimonial = ({
  title,
  renderText,
  renderTitle,
  titleClassName,
  videoSrcURL,
  videoTitle,
  dataTheme,
  renderBigTitle,
  titleBigClassName,
  BigTitle,
  renderForBigText,
  ForBigText,
  padBottom = true,
}) => (
  <section data-theme={dataTheme}>
    <div className={`container container--lg margin-top--lg ${padBottom ? 'margin-bottom--lg' : ''}`}>
      <div className="text--center margin-bottom--md">
        {renderBigTitle && (
          <h2 className={` ${titleBigClassName}`}>{BigTitle}</h2>
        )}
        {renderText && (
          <p className="text--md margin-bottom--md col--md-8 align--center">
            With over 40,000 happy patients already treated, The Joint Academy
            experience is incomparable.
          </p>
        )}
        {renderForBigText && (
          <p className="text--md margin-bottom--md col--md-8 align--center">
            {ForBigText}
          </p>
        )}
        {renderTitle && <h2 className={` ${titleClassName}`}>{title}</h2>}
      </div>
      <div className="case-video">
        <div className="case-video-cover flex flex--center">
          <div className="play flex flex--column flex--center">
            <span
              id="play-button-case"
              className="btn btn--primary flex flex--center aniamtion--ping margin-bottom--sm margin-top--sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 27.999 32.001"
              >
                <path
                  fill="#fff"
                  d="M12.525-14.581l-22-13.006A2.983,2.983,0,0,0-14-25.006V1A3,3,0,0,0-9.475,3.581l22-13A3,3,0,0,0,12.525-14.581Z"
                  transform="translate(14 27.998)"
                />
              </svg>
            </span>
          </div>
        </div>

        <Video videoSrcURL={videoSrcURL} videoTitle={videoTitle} />
      </div>
    </div>
  </section>
);
export default Testimonial;
